import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/BackgroundImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/LandingPage/Heading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/LandingPage/ProfileImage.tsx");
